import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import avatar from "../../assets/myhub/avatar2.png";
import { BiChevronDown, BiLogOut } from "react-icons/bi";
import { useSelector } from "react-redux";

const ProfileDropdown = ({ disconnect }) => {
  const blockchain = useSelector((state) => state.blockchain);
  const hubData = useSelector((state) => state.hubData);

  return (
    <div className="ml-4 text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex items-center space-x-1 w-full px-2 py-1 text-xs sm:text-sm font-medium text-white bg-pinkD hover:bg-pink-800 rounded-md">
            <img src={avatar} alt="avatar" className="h-8 sm:h-10 mr-3 ml-1 mt-1 mb-1" /> {blockchain.account.length <= 17 ? blockchain.account : blockchain.account.substring(0, 14) + "..."}
            <BiChevronDown className="sm:text-lg" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-pinkD rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <>
                    <p className="p-2 text-white flex justify-between">
                      <span>Balance:</span>
                      <span>
                        {blockchain.deyeBalance &&
                          parseFloat(hubData.deyeInWallet).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                        DEYE
                      </span>
                    </p>
                    <p className="p-2 text-white flex justify-between">
                      <span>Claimable:</span>
                      <span>
                        {blockchain.deyeBalance &&
                          parseFloat(blockchain.deyeBalance-hubData.deyeClaimed).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                        DEYE
                      </span>
                    </p>
                  </>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={disconnect}
                    href="/"
                    className={`${
                      active ? "bg-pink-800" : "text-white"
                    } group flex rounded-md items-center text-white w-full p-2 text-sm cursor-pointer`}
                  >
                    <BiLogOut className="mr-1" /> Logout
                  </a>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default ProfileDropdown;
