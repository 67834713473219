import Navbar from "../components/Navbar";
import dark from "../assets/dark2.png";

const Hero = () => {
  return (
    <>
    <section
      id="home"
      className="bg-hero bg-center bg-cover bg-no-repeat bg-fixed h-screen relative"
    >
      <Navbar />
      {/* <img
        src={dark}
        alt="dark logo"
        className="h-20 md:h-32 absolute top-[50%] right-[50%] translate-x-[50%] translate-y-[-30%]"
      /> */}
      <img
        src={dark}
        alt="dark logo"
        className="h-20 md:h-60 absolute top-[47%] right-[50%] translate-x-[50%] translate-y-[-30%]"
      />
    </section>

    <section className="bg-project1 lg:bg-center bg-cover bg-no-repeat py-32 md:py-64">
        <div className="px-5 sm:px-16 md:px-5 lg:px-16 sm:container sm:mx-auto text-white">
          <div className="grid lg:grid-cols-2">
            <div></div>
            <div>
              <h1 className="text-3xl md:text-4xl font-semibold">
                Time Machines <span className="mt-8 md:text-lg">━━ The Limited Edition All-in-One Pass</span>
              </h1>
              <p className="mt-8 md:text-lg">
                Time Machine holders are guaranteed minting spots in all subsequent launches with a discounted price.
                Time Machines also reward holders with DEYE utility tokens on a daily basis.
                Other benefits include exclusive airdrops, giveaways, and events.
                <br/>
                <br/>
              </p>

              <h1 className="text-2xl md:text-2xl font-semibold">
                <span className="text-4xl md:text-4xl font-semibold">Sold Out (1422/1422)</span>
              </h1>
              
              <a
                href="https://opensea.io/collection/time-machines-by-project-dark-eye"
                target="_blank"
              >
                <button
                  className="py-3 px-7 rounded-lg bg-blue-500 hover:bg-blue-700 mt-8 md:mt-10"
                >
                  Opensea
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
      </>
  );
};

export default Hero;
