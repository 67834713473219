// constants
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
// log
import { fetchData } from "../data/dataActions";
import { fetchHubData } from "../hubdata/dataActions";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB_erGBvuEag14ShX81zqvgvRdQyvYaQGY",
  authDomain: "darkeye-v1.firebaseapp.com",
  projectId: "darkeye-v1",
  storageBucket: "darkeye-v1.appspot.com",
  messagingSenderId: "226359808658",
  appId: "1:226359808658:web:a80b44190f381b67500c4b",
  measurementId: "G-WKDVRLVVGK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};
const getBalanceSuccess = (payload) => {
  return {
    type: "GET_BALANCE",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    
    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: false, // optional
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: "ab934307931e46cb9519b550822421dc"
          }
        },
        coinbasewallet: {
          package: CoinbaseWalletSDK, // Required
          options: {
            appName: "Project Dark Eye Web", // Required
            infuraId: "ab934307931e46cb9519b550822421dc", // Required
          }
        }
      } // required
    });
    web3Modal.clearCachedProvider();
    
    const provider = await web3Modal.connect();
    await provider.enable();
    const web3 = new Web3(provider);
    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    const networkId = await web3.eth.net.getId();
    const gasPrice = await web3.eth.getGasPrice();
    // console.log(`address: ${address}`);
    // console.log(`networkId: ${networkId}`);

    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();

    const commanderAbiResponse = await fetch("/config/commander_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const commanderAbi = await commanderAbiResponse.json();
  
    const deyeAbiResponse = await fetch("/config/deye_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const deyeAbi = await deyeAbiResponse.json();

    const marketAbiResponse = await fetch("/config/marketplace_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const marketAbi = await marketAbiResponse.json();

    const poolPositionAbiResponse = await fetch("/config/pool_position_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const poolPositionAbi = await poolPositionAbiResponse.json();

    const stakerAbiResponse = await fetch("/config/staker_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const stakerAbi = await stakerAbiResponse.json();

    const wethAbiResponse = await fetch("/config/weth_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const wethAbi = await wethAbiResponse.json();
  
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();

    if (networkId == CONFIG.NETWORK.ID) {
      const SmartContractObj = new web3.eth.Contract(
        abi,
        CONFIG.CONTRACT_ADDRESS
      );
      const deyeContract = new web3.eth.Contract(
        deyeAbi,
        CONFIG.DEYE_CONTRACT_ADDRESS
      );
      const marketContract = new web3.eth.Contract(
        marketAbi,
        CONFIG.MARKET_CONTRACT_ADDRESS
      );
      const poolPositionContract = new web3.eth.Contract(
        poolPositionAbi,
        CONFIG.POOL_POSITION_CONTRACT_ADDRESS
      );
      const stakerContract = new web3.eth.Contract(
        stakerAbi,
        CONFIG.STAKER_CONTRACT_ADDRESS
      );
      const wethContract = new web3.eth.Contract(
        wethAbi,
        CONFIG.WETH_CONTRACT_ADDRESS
      );
      const commanderContract = new web3.eth.Contract(
        commanderAbi,
        CONFIG.COMMANDER_CONTRACT_ADDRESS
      );
      dispatch(
        connectSuccess({
          account: address,
          smartContract: SmartContractObj,
          deyeContract: deyeContract,
          marketContract: marketContract,
          poolPositionContract: poolPositionContract,
          stakerContract: stakerContract,
          wethContract: wethContract,
          commanderContract: commanderContract,
          gasPrice: gasPrice * 75000 * 1e-18,
          web3: web3,
        })
      );
      if (provider.on) {
        provider.on("close", () => { window.location.reload(); });
        provider.on("accountsChanged", async (accounts) => { dispatch(updateAccount(accounts[0])); });
        provider.on("chainChanged", async (chainId) => { window.location.reload(); });
        provider.on("networkChanged", async (networkId) => { window.location.reload(); });
      }
    } else {
      dispatch(connectFailed(`Please change your wallet network to ${CONFIG.NETWORK.NAME}.`));
    }

    // // get balance
    // var ethBalance;
    // await web3.eth.getBalance(address).then((result) => {
    //   ethBalance = result;
    // });
    // const etherValue = Web3.utils.fromWei(
    //   web3.utils.toBN(ethBalance),
    //   "ether"
    // );

    // get deye balance
    var deyeValue;
    var deyeProof;
    const docRef = doc(db, "deye-proof", address);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      deyeValue = docSnap.data().balance;
      deyeProof = docSnap.data().proof;
    } else {
      const docRef2 = doc(db, "deye-proof", address.toLowerCase());
      const docSnap2 = await getDoc(docRef2);
      if (docSnap2.exists()) {
        // console.log("Document data:", docSnap2.data());
        deyeValue = docSnap2.data().balance;
        deyeProof = docSnap2.data().proof;
      } else {
        deyeValue = 0;
        deyeProof = "";
      }
    }

    var tokenIds;
    const docRef3 = doc(db, "time_machine_token_owner", address.toLowerCase());
    const docSnap3 = await getDoc(docRef3);
    if (docSnap3.exists()) {
      // console.log("Document data:", docSnap2.data());
      tokenIds = docSnap3.data().token_ids;
    } else {
      tokenIds = [];
    }

    var commanderTokenIds;
    const docRefC1 = doc(db, "commander_token_owner", address.toLowerCase());
    const docSnapC1 = await getDoc(docRefC1);
    if (docSnapC1.exists()) {
      // console.log("Document data:", docSnap2.data());
      commanderTokenIds = docSnapC1.data().token_ids;
    } else {
      commanderTokenIds = [];
    }
    
    var commanderMintPrice = 0.088;
    var goldCount = 0;
    var silverCount = 0;
    var blackCount = 0;
    for (var i = 0; i < tokenIds.length; i++) {
      if (tokenIds[i] <= 300) {
        goldCount = goldCount + 1;
      } else if (tokenIds[i] <= 1300) {
        silverCount = silverCount + 1;
      } else {
        blackCount = blackCount + 1;
      }
    }
    if (goldCount + silverCount + blackCount > 0) {
      commanderMintPrice = 0.058;
    }

    var remainingPool;
    const docRef4 = doc(db, "deye_metadata", "metadata");
    const docSnap4 = await getDoc(docRef4);
    if (docSnap4.exists()) {
      remainingPool = docSnap4.data().remaining_pool;
    } else {
      remainingPool = null;
    }

    var stakedTokenIds;
    const docRef5 = doc(db, "staked_pool_token", address.toLowerCase());
    const docSnap5 = await getDoc(docRef5);
    if (docSnap5.exists()) {
      // console.log("Document data:", docSnap2.data());
      stakedTokenIds = docSnap5.data().tokens;
    } else {
      stakedTokenIds = [];
    }

    // get commander allowance
    var commanderValue;
    var commanderProof;
    const docRef6 = doc(db, "commander-wl-proof", address);
    const docSnap6 = await getDoc(docRef6);
    if (docSnap6.exists()) {
      // console.log("Document data:", docSnap.data());
      commanderValue = docSnap6.data().allowance;
      commanderProof = docSnap6.data().proof;
    } else {
      const docRef7 = doc(db, "commander-wl-proof", address.toLowerCase());
      const docSnap7 = await getDoc(docRef7);
      if (docSnap7.exists()) {
        // console.log("Document data:", docSnap2.data());
        commanderValue = docSnap7.data().allowance;
        commanderProof = docSnap7.data().proof;
      } else {
        commanderValue = 0;
        commanderProof = "";
      }
    }

    dispatch(
      getBalanceSuccess({
        deyeBalance: deyeValue,
        deyeProof: deyeProof,
        goldCount: goldCount,
        silverCount: silverCount,
        blackCount: blackCount,
        remainingPool: remainingPool,
        stakedTokenIds: stakedTokenIds,
        commanderAllowed: commanderValue,
        commanderProof: commanderProof,
        commanderMintPrice: commanderMintPrice,
        commanderTokenIds: commanderTokenIds,
      })
    );
    /*
    } else {
      dispatch(connectFailed("Install Metamask."));
    }
    */
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
    dispatch(fetchHubData(account));
  };
};
