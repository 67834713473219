import {
  AiOutlineHome,
  AiOutlineUser,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { FaGlobe } from "react-icons/fa";
import { CgCollage } from "react-icons/cg";

const SideMenu = ({ component, setComponent }) => {
  const tabs = [
    {
      id: 1,
      icon: (
        <AiOutlineHome
          className={`${component === "home" && "text-pinkD"} text-2xl`}
        />
      ),
      link: "home",
    },
    // {
    //   id: 2,
    //   icon: (
    //     <AiOutlineUser
    //       className={`${component === "user" && "text-pinkD"} text-2xl`}
    //     />
    //   ),
    //   link: "user",
    // },
    // {
    //   id: 3,
    //   icon: (
    //     <FaGlobe
    //       className={`${component === "globe" && "text-pinkD"} text-2xl`}
    //     />
    //   ),
    //   link: "globe",
    // },
    // {
    //   id: 4,
    //   icon: (
    //     <CgCollage
    //       className={`${component === "collage" && "text-pinkD"} text-2xl`}
    //     />
    //   ),
    //   link: "collage",
    // },
    {
      id: 2,
      icon: (
        <AiOutlineShoppingCart
          className={`${component === "marketplace" && "text-pinkD"} text-2xl`}
        />
      ),
      link: "marketplace",
    },
    {
      id: 3,
      icon: (
        <AiOutlineUser
          className={`${component === "commander" && "text-pinkD"} text-2xl`}
        />
      ),
      link: "commander",
    },
  ];

  return (
    <div className="px-10 py-3 bg-pinkD mb-2 flex flex-row lg:flex-col items-center justify-between lg:justify-start w-full lg:w-32 h-full lg:rounded-r-lg shadow-lg">
      {tabs.map((tab) => (
        <button
          onClick={() => setComponent(tab.link)}
          key={tab.id}
          className={`p-2 rounded-full ${
            component === tab.link ? "bg-white" : "bg-white/25 "
          } my-5`}
        >
          {tab.icon}
        </button>
      ))}
    </div>
  );
};

export default SideMenu;
