// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      // let totalSupply = await store
      //   .getState()
      //   .blockchain.smartContract.methods.totalSupply()
      //   .call();
      // let mintStatus = await store
      //   .getState()
      //   .blockchain.smartContract.methods.contractStatus()
      //   .call();
      // let allowedQuantity = await store
      //   .getState()
      //   .blockchain.smartContract.methods.mintAllowedQuantityForAddress(store.getState().blockchain.account, mintStatus)
      //   .call();
      // let mintedQuantity = await store
      //   .getState()
      //   .blockchain.smartContract.methods.balanceOf(store.getState().blockchain.account)
      //   .call();

      let totalMinted = await store
        .getState()
        .blockchain.commanderContract.methods.totalMinted()
        .call();
      let contractStatus = await store
        .getState()
        .blockchain.commanderContract.methods.contractStatus()
        .call();
      let mintedQuantity = await store
        .getState()
        .blockchain.commanderContract.methods.getMintedQuantity(store.getState().blockchain.account)
        .call();
      let currentPrice = 0.088;
      let nextPrice = (currentPrice - 0.05 > 0.088) ? (currentPrice - 0.05) : 0.088;
      let nextTime = Date.now();
      nextTime = nextTime - nextTime % 3600000 + 3615000;
      console.log(currentPrice);
      console.log(nextPrice);
      console.log(nextTime);


      dispatch(
        fetchDataSuccess({
          // totalSupply: totalSupply,
          // mintStatus: mintStatus,
          // allowedQuantity: mintStatus == 3 ? 5 : Math.max(allowedQuantity-mintedQuantity, 0),
          totalMinted: totalMinted,
          contractStatus: contractStatus,
          mintedQuantity: mintedQuantity,
          currentPrice: currentPrice,
          nextPrice: nextPrice,
          nextTime: nextTime,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
