import { Disclosure, Transition } from "@headlessui/react";

const faqList = [
  {
    id: 1,
    question: "Which blockchain network is Project Dark Eye deploying on?",
    answer:
      "All Project Dark Eye tokens (including NFTs) are deployed on Ethereum.",
  },
  {
    id: 2,
    question: "What is the mint price for the Commanders?",
    answer:
      "The mint price is less than 0.01 ETH. The exact number depends on the ethereum gas fees. An estimated price is provided after you connect your wallet on the mint page.",
  },
  {
    id: 3,
    question: "What is the difference between Unlicensed Commanders and Licensed Commanders?",
    answer: `Licensed Commanders include utilities (e.g. Daily Token Rewards) that Unlicensed Commanders do not have.
    Both Licensed and Unlicensed Commander owners get a unique 4k 3D avatar and naming rights.`,
  },
  {
    id: 4,
    question: "What is the burning mechanism for the Commanders Collection?",
    answer: `To avoid the project being attracted to short term holders/flippers, a burning mechanism is introduced to the Commanders Collection.
    Any Commander NFT listed under 0.1 ETH on the secondary market may get burnt. The burning mechanism will be lifted 72 hours after the reveal.`,
  },
];

const Faq = () => {
  return (
    <section
      id="FAQ"
      className="bg-faq bg-center bg-cover bg-no-repeat bg-fixed"
    >
      <div className="bg-blackOverlay py-20 md:py-24">
        <div className="px-5 xl:px-16 xl:container xl:mx-auto text-white">
          <h1 className="capitalize text-3xl md:text-4xl text-center font-semibold mb-16">
            Frequently Asked Questions
          </h1>

          {faqList.map((faq) => (
            <div
              className="grid sm:grid-cols-6 lg:grid-cols-8 p-2 mt-5 align-left"
              key={faq.id}
            >
              <div className="col-start-2 lg:col-start-3 col-span-4">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex items-center text-left space-x-10 py-2 font-semibold md:text-lg focus:outline-none">
                        <span>{faq.question}</span>
                        <div>{open ? "-" : "+"}</div>
                      </Disclosure.Button>
                      <Transition
                        enter="transition duration-300 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-200 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel
                          className="pt-4 pb-2 text-sm md:text-base"
                          static
                        >
                          <p>{faq.answer}</p>
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
                <hr className="mt-4 border-gray-500" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;
