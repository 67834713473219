const initialState = {
  loading: false,
  totalMinted: 0,
  contractStatus: 0,
  mintedQuantity: 0,
  currentPrice: null,
  nextPrice: null,
  nextTime: null,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalMinted: action.payload.totalMinted,
        contractStatus: action.payload.contractStatus,
        mintedQuantity: action.payload.mintedQuantity,
        currentPrice: action.payload.currentPrice,
        nextPrice: action.payload.nextPrice,
        nextTime: action.payload.nextTime,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
