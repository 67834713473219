const initialState = {
  loading: false,
  account: null,
  smartContract: null,
  deyeContract: null,
  marketContract: null,
  poolPositionContract: null,
  stakerContract: null,
  wethContract: null,
  commanderContract: null,
  web3: null,
  errorMsg: "",
  deyeBalance: null,
  deyeProof: "",
  goldCount: 0,
  silverCount: 0,
  blackCount: 0,
  remainingPool: 0,
  stakedTokenIds: null,
  commanderAllowed: null,
  commanderProof: "",
  commanderMintPrice: null,
  gasPrice: null,
  commanderTokenIds: [],
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONNECTION_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "CONNECTION_SUCCESS":
      return {
        ...state,
        loading: false,
        account: action.payload.account,
        smartContract: action.payload.smartContract,
        deyeContract: action.payload.deyeContract,
        marketContract: action.payload.marketContract,
        poolPositionContract: action.payload.poolPositionContract,
        stakerContract: action.payload.stakerContract,
        wethContract: action.payload.wethContract,
        commanderContract: action.payload.commanderContract,
        gasPrice: action.payload.gasPrice,
        web3: action.payload.web3,
      };
    case "GET_BALANCE":
      return {
        ...state,
        loading: false,
        deyeBalance: action.payload.deyeBalance,
        deyeProof: action.payload.deyeProof,
        goldCount: action.payload.goldCount,
        silverCount: action.payload.silverCount,
        blackCount: action.payload.blackCount,
        remainingPool: action.payload.remainingPool,
        stakedTokenIds: action.payload.stakedTokenIds,
        commanderAllowed: action.payload.commanderAllowed,
        commanderProof: action.payload.commanderProof,
        commanderMintPrice: action.payload.commanderMintPrice,
        commanderTokenIds: action.payload.commanderTokenIds,
      };
    case "CONNECTION_FAILED":
      return {
        ...initialState,
        loading: false,
        errorMsg: action.payload,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        account: action.payload.account,
      };
    default:
      return state;
  }
};

export default blockchainReducer;
