import nft2 from "../assets/nft2.jpg";

const NftProjects = () => {
  return (
    <>
      {/* <section id="collections" className="bg-project1 lg:bg-center bg-cover bg-no-repeat py-32 md:py-64">
        <div className="px-5 sm:px-16 md:px-5 lg:px-16 sm:container sm:mx-auto text-white">
          <div className="grid lg:grid-cols-2">
            <div></div>
            <div>
              <h1 className="text-3xl md:text-4xl font-semibold">
                Time Machines
              </h1>
              <p className="mt-8 md:text-lg">
                Time Machine is a limited edition all-in-one pass to enter our Metaverse (DEYE-Universe).
                <br/>
                <br/>
                Time Machine holders are guaranteed minting spots in all NFT launches by Project Dark Eye.
                Time Machines also reward holders with DEYE utility tokens on a daily basis.
                Other benefits include exclusive airdrops, giveaways, and events.
              </p>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section id="collections" className="bg-project bg-center bg-cover bg-no-repeat bg-fixed">
        <div className="bg-blackOverlay py-24 md:py-40">
          <div className="px-5 sm:px-16 md:px-5 lg:px-16 sm:container sm:mx-auto text-white">
            <div className="grid md:grid-cols-2 gap-10">
              <div className="self-center order-2 md:order-1">
                <h1 className="text-3xl md:text-4xl font-semibold">
                  Commanders
                </h1>
                <p className="mt-8 md:text-lg">
                  Commanders is a collection of 10000+ 3D avatars, where each avatar represents a Commander character in DEYE-Universe.
                  Each commander will experience different events, grow in different directions and change DEYE-Universe in a unique way.
                  <br/>
                  <br/>
                  Every 24 hours, every Commander is dispatched to an exploration, which grows the Commander's Ability Traits and rewards Commander with loots
                  (items, equipment, planets). Each Commander holder is also rewarded with DEYE utility tokens on a daily basis.
                  <br/>
                  <br/>
                  Designed by an ex-RTFKT artist team, each Commander has a unique 3D avatar, featuring over 200 Visual Traits in the whole collection.
                </p>
              </div>
              <div className="order-1 md:order-2">
                <img src={nft2} alt="nft" className="object-fill" />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section id="collections"  className="bg-project2 lg:bg-center bg-cover bg-no-repeat py-32 md:py-64">
        <div className="px-5 sm:px-16 md:px-5 lg:px-16 sm:container sm:mx-auto text-white">
          <div className="grid lg:grid-cols-2">
            <div></div>
            <div>
              <h1 className="text-3xl md:text-4xl font-semibold">
                Planets
              </h1>
              <p className="mt-8 lg:text-lg">
                Planet is the “Land” equivalent in DEYE-Universe. 100% of the Planet supply are rewards to the Commander holders through explorations.
                <br/>
                <br/>
                A Planet owner may allow Commanders to set their base on the planet with a price set by the owner.
                Commanders whose bases are closer to the undiscovered space in DEYE-Universe have high chance of discovering new Planets and receiving new loots.
                <br/>
                <br/>
                More built-in utilities and customizable utilities for Planets will be announced.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NftProjects;
