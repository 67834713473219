import circle from "../assets/circle.png";
import dot from "../assets/dot.png";

const Roadmap = () => {
  return (
    <section
      id="roadmap"
      className="bg-galaxy bg-center bg-cover bg-no-repeat bg-fixed"
    >
      <div className="bg-blackOverlay py-20 md:py-24">
        <div className="px-10 lg:px-16 sm:container sm:mx-auto text-white">
          <h1 className="text-3xl md:text-4xl text-center font-semibold">
            Roadmap
          </h1>

          <div className="grid grid-cols-1 lg:grid-cols-2 mt-10 lg:mt-24">
            <div className="hidden lg:block">
              <h1 className="text-right text-2xl m-10">Phase 01</h1>
            </div>
            <div className="relative border-l-2">
              <img src={dot} alt="dot" className="-mt-2 -ml-[0.6rem]" />
              <img src={circle} alt="dot" className="absolute -left-5 top-9" />

              <h1 className="text-xl md:text-2xl m-10 lg:hidden">Phase 01</h1>

              <div className="py-2 rounded-lg bg-pinkD w-44 text-center ml-10 mt-6">
                <h1 className="font-semibold text-lg">2022 April</h1>
              </div>

              <ul className="my-5 ml-10 p-3 rounded-lg marker:text-pinkD marker:text-pinkD list-disc">
                <li>
                  Launch Time Machine Collection
                </li>
                <li className="my-3">
                  Deploy DEYE utility token
                </li>
                <li>
                  Daily DEYE token reward starts for Machine holders
                </li>
              </ul>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="hidden lg:block">
              <div className="py-2 rounded-lg bg-pinkD w-44 text-center ml-auto mr-10 mt-10">
                <h1 className="font-semibold text-lg">2022 May/June</h1>
              </div>

              <ul className="my-5 ml-28 mr-10 p-3 rounded-lg marker:text-pinkD list-disc">
                <li>
                  Launch Commander collection
                </li>
                <li className="my-3">
                  Commander exploration starts on a daily basis
                </li>
                <li>
                  Commander holders start to receive Planets from explorations
                </li>
                <li className="mt-3">
                  Launch Planet Base System
                </li>
              </ul>
            </div>

            <div className="relative border-l-2">
              <img
                src={circle}
                alt="circle"
                className="absolute -left-5 top-9"
              />
              <h1 className="text-xl md:text-2xl m-10">Phase 02</h1>
              <div className="py-2 rounded-lg bg-pinkD w-44 text-center ml-10 mt-10 lg:hidden">
                <h1 className="font-semibold text-lg">2022 May/June</h1>
              </div>
              <ul className="my-5 ml-10 p-3 rounded-lg marker:text-pinkD list-disc lg:hidden">
                <li>
                  Launch Commander collection
                </li>
                <li className="my-3">
                  Commander exploration starts on a daily basis
                </li>
                <li>
                  Commander holders start to receive Planets from explorations
                </li>
                <li className="mt-3">
                  Launch Planet Base System
                </li>
              </ul>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="hidden lg:block">
              <h1 className="text-right text-2xl m-10">Phase 03</h1>
            </div>
            <div className="relative border-l-2">
              <img src={circle} alt="dot" className="absolute -left-5 top-9" />

              <h1 className="text-xl md:text-2xl m-10 lg:hidden">Phase 03</h1>

              <div className="py-2 rounded-lg bg-pinkD w-44 text-center ml-10 mt-9">
                <h1 className="font-semibold text-lg">2022 June/July</h1>
              </div>

              <ul className="my-5 ml-10 p-3 rounded-lg marker:text-pinkD list-disc">
                <li>
                  Launch more exploration loot types
                </li>
                <li className="my-3">
                  Launch more Planet utilities
                </li>
                <li>
                  Launch Marketplace with DEYE token liquidity supply
                </li>
                <li className="mt-3">
                  More details on Phase 3 will be revealed in the next version
                  of the whitepaper
                </li>
              </ul>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="hidden lg:block">
              <div className="py-2 rounded-lg bg-pinkD w-44 text-center ml-auto mr-10 mt-10">
                <h1 className="font-semibold text-lg">2022 H2</h1>
              </div>

              <ul className="my-5 ml-28 mr-10 p-3 rounded-lg marker:text-pinkD list-disc">
                <li>
                  Launch World V1 alpha testing
                </li>
                <li className="my-3">
                  More details will be revealed in a future version of the whitepaper
                </li>
              </ul>
            </div>
            <div className="relative border-l-2">
              <img
                src={circle}
                alt="circle"
                className="absolute -left-5 top-9"
              />
              <h1 className="text-xl md:text-2xl m-10">Phase 04</h1>
              <div className="py-2 rounded-lg bg-pinkD w-44 text-center ml-10 mt-10 lg:hidden">
                <h1 className="font-semibold text-lg">2022 H2</h1>
              </div>
              <ul className="my-5 ml-10 p-3 rounded-lg marker:text-pinkD list-disc lg:hidden">
                <li>
                  Launch World V1 alpha testing
                </li>
                <li className="my-3">
                  More details will be revealed in a future version of the whitepaper
                </li>
              </ul>
              <img src={dot} alt="dot" className="absolute -bottom-1 -left-3" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
