import nft1 from "../assets/nft1.png";
import nft2 from "../assets/nft2.jpg";
import { BsFillPlayFill } from "react-icons/bs";
import clip from "../assets/clip.jpg";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";
import { connect } from "../redux/blockchain/blockchainActions";
import Countdown, {zeroPad} from 'react-countdown';

const Mint = ({ CONFIG }) => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintAmount, setMintAmount] = useState(0);
  const [error, setError] = useState("");

  const claimNFTs = () => {
    if (mintAmount <= 0) {
      return;
    }
    // let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(0.008 * mintAmount * 1000000000000000000);
    let totalGasLimit = String(gasLimit + 2500 * (mintAmount - 1));
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting in progress...`);
    setClaimingNft(true);
    blockchain.commanderContract.methods
      .mintPublic(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: "0x6D0065855d7171937eD49e60c316d2Fb07200c0b",
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Minting succeeded!`
        );
        setClaimingNft(false);
        setMintAmount(0);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 0) {
      newMintAmount = 0;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = (remaining) => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 5) {
      newMintAmount = 5;
    }
    if (newMintAmount > remaining) {
      newMintAmount = remaining;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds }) => (
    <span>
      { days > 0 ? (
          <> {days} Days {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)} </>
        ) : (
          <> {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)} </>
        )
      }
    </span>
  );

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    if (blockchain.errorMsg) {
      setError(blockchain.errorMsg);
    }
  }, [blockchain.errorMsg]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setError(" ");
    }, 3000);
    return () => clearTimeout(timer);
  }, [error]);

  return (
    <>
      {error !== "" ? (
        <p
          className={`text-white rounded-md font-semibold my-2 fixed bottom-5 right-10 z-10 ${
            error !== " " && "p-2"
          } bg-red-600`}
        >
          {error}
        </p>
      ) : null}

      <section id="mint" className="bg-project bg-center bg-cover bg-no-repeat bg-fixed">
        <div className="bg-blackOverlay py-24 md:py-40">
          <div className="px-5 sm:px-16 md:px-5 lg:px-16 sm:container sm:mx-auto text-white">
            <div className="grid md:grid-cols-2 gap-10">
              <div className="self-center order-2 md:order-1">
                <h1 className="text-3xl md:text-4xl font-semibold">
                  Commanders
                </h1>
                <p className="mt-8 md:text-lg">
                  Commanders is a collection of 6666 3D avatars with over 200 Visual Traits, where each avatar represents a Commander character in DEYE-Universe.
                  Each commander will experience different events, grow in different directions and change DEYE-Universe in a unique way.
                  <br/>
                  <br/>
                  Each Commander minter will be able to name their Commander after the minting ends. Commander owners can get their Commander Licensed
                  (See FAQ for License explanation) later.
                  {/* Every 24 hours, every Commander is dispatched to an exploration, which grows the Commander's Ability Traits and rewards Commander with loots
                  (items, equipment, planets). Each Commander holder is also rewarded with DEYE utility tokens on a daily basis. */}
                  <br/>
                  <br/>
                  Commanders also come with burning mechanism that protects the collection from flippers (See FAQ for details).
                </p>

                <br/>

                {blockchain.account === "" || blockchain.account === null || blockchain.smartContract === null ? (
                  <>
                    <h1 className="text-2xl md:text-2xl font-semibold">
                      Mint Price: <span className="text-4xl md:text-4xl font-semibold">{"< "}0.01 ETH{" "}</span> (Gas Included)
                    </h1>
                    <br/>
                    <h1 className="text-2xl md:text-2xl font-semibold">
                      Total Supply: <span className="text-4xl md:text-4xl font-semibold">6666</span>
                    </h1>
                    <br/>
                    {Date.now() < 1658469600*1000 ? (
                      <>
                        <h1 className="text-2xl md:text-2xl font-semibold">
                          Public Sale Starting in <span className="text-4xl md:text-4xl font-semibold"><Countdown date={1658469600*1000} renderer={renderer}/></span>
                        </h1>
                      </>
                    ) : (
                      <>
                        <h1 className="text-2xl md:text-2xl font-semibold">
                          Public Sale: <span className="text-4xl md:text-4xl font-semibold">In Progress</span>
                        </h1>
                        <br/>
                        <h1 className="text-lg md:text-xl font-semibold text-yellow-300">
                          (Connect Wallet to Participate)
                        </h1>
                      </>
                    )}

                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                      className="py-3 px-7 rounded-lg bg-pinkD hover:bg-pink-800 mt-8 md:mt-10"
                    >
                      Connect Wallet
                    </button>
                  </>
                ) : (
                  <>
                    {blockchain.gasPrice === null ? (
                      <>
                        <h1 className="text-2xl md:text-2xl font-semibold">
                          Estimated Price: <span className="text-4xl md:text-4xl font-semibold">Loading...</span>
                        </h1>
                      </>
                    ) : (
                      <>
                        <h1 className="text-2xl md:text-2xl font-semibold">
                          Estimated Price: <span className="text-4xl md:text-4xl font-semibold">
                            {parseFloat(blockchain.gasPrice + 0.008).toFixed(5).toString()} ETH{" "}
                          </span>
                          (Gas Included)
                        </h1>
                      </>
                    )}
                    <br/>
                    <h1 className="text-2xl md:text-2xl font-semibold">
                      {data.totalSupply === null ? (
                        <>
                          Remaining Supply: <span className="text-4xl md:text-4xl font-semibold">Loading...</span>
                        </>
                      ) : (
                        <>
                          Remaining Supply: <span className="text-4xl md:text-4xl font-semibold">{6666 - data.totalMinted} / {6666}</span>
                        </>
                      )}
                    </h1>
                    <br/>
                    {(data.contractStatus != 2 || Date.now() < 1657090800*1000) ? (
                      <>
                        <h1 className="text-2xl md:text-2xl font-semibold">
                          Public Sale Starting in <span className="text-4xl md:text-4xl font-semibold"><Countdown date={1658469600*1000} renderer={renderer}/></span>
                        </h1>
                      </>
                    ) : (
                      <>
                        <h1 className="text-2xl md:text-2xl font-semibold text-yellow-300">
                          You are allowed to mint at most 5 per wallet ({Math.max(5-data.mintedQuantity, 0)} remaining)
                        </h1>

                        <div className="flex items-center space-x-5 mt-10">
                          <button
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              decrementMintAmount();
                            }}
                            className="bg-white text-black px-4 py-2 rounded-md text-xl"
                          >
                            -
                          </button>

                          <p className="px-5 py-2 rounded-lg border-2">
                            {mintAmount}
                          </p>

                          <button
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              incrementMintAmount(Math.min(Math.max(5-data.mintedQuantity, 0), 6666-data.totalMinted));
                            }}
                            className="bg-white text-black px-4 py-2 rounded-md"
                          >
                            +
                          </button>

                          <button
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              claimNFTs();
                              window.gtag('event', 'conversion', {'send_to': 'AW-10859019743/G1mnCPOS7M8DEN_7_bko'});
                              getData();
                            }}
                            className="py-3 px-7 rounded-lg bg-pinkD hover:bg-pink-800"
                          >
                            {claimingNft ? "Minting..." : "Mint"}
                          </button>
                        </div>
                      </>
                    )}
                  </>
                )}

                {/* {blockchain.account === "" ||
                blockchain.smartContract === null ? ( */}
                {/* {blockchain.account === "" || blockchain.account === null || blockchain.smartContract === null ? (
                  <>
                    <br/>
                    <h1 className="text-2xl md:text-2xl font-semibold">
                      Total Supply: <span className="text-4xl md:text-4xl font-semibold">{CONFIG.MAX_SUPPLY}</span>
                    </h1>
                    <br/>
                    <h1 className="text-2xl md:text-2xl font-semibold">
                      Mint is LIVE
                    </h1>
                    <br/>

                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                      className="py-3 px-7 rounded-lg bg-pinkD hover:bg-pink-800 mt-8 md:mt-10"
                    >
                      Connect Wallet
                    </button>
                  </>
                ) : (
                  <>
                    <br/>
                    <h1 className="text-2xl md:text-2xl font-semibold">
                      {data.totalSupply === null ? (
                        <>
                          Remaining Supply: <span className="text-4xl md:text-4xl font-semibold">Loading...</span>
                        </>
                      ) : (
                        <>
                          Remaining Supply: <span className="text-4xl md:text-4xl font-semibold">{CONFIG.MAX_SUPPLY - data.totalSupply} / {CONFIG.MAX_SUPPLY}</span>
                        </>
                      )}
                    </h1>
                    <br/>
                    <h1 className="text-2xl md:text-2xl font-semibold">
                      Mint is LIVE
                    </h1>

                    <br/>
                    <h1 className="text-2xl md:text-2xl font-semibold text-yellow-300">
                    {data.mintStatus !== 3 ? (
                      <>
                        You are allowed to mint at most 5 per transaction
                      </>
                      ) : (<>
                        {data.mintStatus == 3 ? (
                          <>
                            You are allowed to mint at most 5 per transaction
                          </>
                        ) : (
                          <>
                          </>
                        )}
                      </>
                    )}
                    </h1>

                    <div className="flex items-center space-x-5 mt-10">
                      <button
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                        className="bg-white text-black px-4 py-2 rounded-md text-xl"
                      >
                        -
                      </button>

                      <p className="px-5 py-2 rounded-lg border-2">
                        {mintAmount}
                      </p>

                      <button
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                        className="bg-white text-black px-4 py-2 rounded-md"
                      >
                        +
                      </button>

                      <button
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                        className="py-3 px-7 rounded-lg bg-pinkD hover:bg-pink-800"
                      >
                        {claimingNft ? "Minting..." : "Mint"}
                      </button>
                    </div>
                  </>
                )} */}
                <p className="mt-8 text-lg">{feedback}</p>
              </div>
              <div className="order-1 md:order-2">
                <img src={nft2} alt="nft" className="object-fill" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Mint;
