import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import MyHub from "./pages/MyHub";
import "./App.css";

// import ReactGA from 'react-ga';

// const TRACKING_ID = "UA-222795689-1";
// ReactGA.initialize(TRACKING_ID);

function App() {
  const blockchain = useSelector((state) => state.blockchain);
  const [error, setError] = useState("");

  useEffect(() => {
    if (blockchain.errorMsg) {
      setError(blockchain.errorMsg);
    }
  }, [blockchain.errorMsg]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setError(" ");
    }, 3000);
    return () => clearTimeout(timer);
  }, [error]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="my-hub" element={<MyHub />} />
      </Routes>

      {error !== "" ? (
        <p
          className={`text-white rounded-md font-semibold my-2 fixed bottom-5 right-10 z-10 ${
            error !== " " && "p-2"
          } bg-red-600`}
        >
          {error}
        </p>
      ) : null}
    </>
  );
}

export default App;
