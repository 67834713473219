const initialState = {
  loading: false,
  deyeInWallet: 0,
  deyeClaimed: 0,
  marketDeyeAllowance: 0,
  activeItems: null,
  notStartedItems: null,
  soldItems: null,
  poolDeye: 0,
  poolEth: 0,
  poolPositions: null,
  stakedPositions: null,
  commanderCount: 0,
  error: false,
  errorMsg: "",
};

const hubDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_HUB_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_HUB_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        deyeInWallet: action.payload.deyeInWallet,
        deyeClaimed: action.payload.deyeClaimed,
        marketDeyeAllowance: action.payload.marketDeyeAllowance,
        activeItems: action.payload.activeItems,
        notStartedItems: action.payload.notStartedItems,
        soldItems: action.payload.soldItems,
        poolDeye: action.payload.poolDeye,
        poolEth: action.payload.poolEth,
        poolPositions: action.payload.poolPositions,
        stakedPositions: action.payload.stakedPositions,
        commanderCount: action.payload.commanderCount,
        error: false,
        errorMsg: "",
      };
    case "CHECK_HUB_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default hubDataReducer;
