const Footer = () => {
  return (
    <footer className="pb-1 pt-3 px-5 bg-pinkD">
      <p className="text-center text-white pb-3">
        &copy;ProjectDarkEye {new Date().getFullYear()}. All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
