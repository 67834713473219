import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Faq from "../sections/Faq";
import Hero from "../sections/Hero";
import Mint from "../sections/Mint";
import NftProjects from "../sections/NftProjects";
import Roadmap from "../sections/Roadmap";
// import Team from "./sections/Team";

function Home() {
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
  });

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <>
      <Hero />
      <Mint CONFIG={CONFIG} />
      <NftProjects />
      <Roadmap />
      <Faq />
      <Footer />
    </>
  );
}

export default Home;
