// log
import Web3 from "web3";
import store from "../store";

const fetchHubDataRequest = () => {
  return {
    type: "CHECK_HUB_DATA_REQUEST",
  };
};

const fetchHubDataSuccess = (payload) => {
  return {
    type: "CHECK_HUB_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchHubDataFailed = (payload) => {
  return {
    type: "CHECK_HUB_DATA_FAILED",
    payload: payload,
  };
};

const poolAddress = '0xb690cb96839fe494a7427f9bb1da922872f4e2d2';
const deyeAddress = '0xE81b6ef5eF04972D9d0548848024058b59d00Ff0';

function reverseArr(input) {
  var ret = new Array;
  for(var i = input.length-1; i >= 0; i--) {
      ret.push(input[i]);
  }
  return ret;
}

export const fetchHubData = () => {
  return async (dispatch) => {
    dispatch(fetchHubDataRequest());
    try {
      let deyeInWallet = await store
        .getState()
        .blockchain.deyeContract.methods.balanceOf(store.getState().blockchain.account)
        .call() / 1000000000000000000;
      let deyeClaimed = await store
        .getState()
        .blockchain.deyeContract.methods.claimed(store.getState().blockchain.account)
        .call();
      let marketDeyeAllowance = await store
        .getState()
        .blockchain.deyeContract.methods.allowance(store.getState().blockchain.account, "0xa68f3dbf1bfe72dfe84b6eec568bd6ed76e2b02b")
        .call();
      let activeItems = await store
        .getState()
        .blockchain.marketContract.methods.getItemListByState(1)
        .call();
      let notStartedItems = await store
        .getState()
        .blockchain.marketContract.methods.getItemListByState(0)
        .call();
      let soldItems = await store
        .getState()
        .blockchain.marketContract.methods.getItemListByState(2)
        .call();
      soldItems = reverseArr(soldItems);
      let poolDeye = await store
        .getState()
        .blockchain.deyeContract.methods.balanceOf(poolAddress)
        .call() / 1000000000000000000;
      let poolEth = await store
       .getState()
       .blockchain.wethContract.methods.balanceOf(poolAddress)
       .call() / 1000000000000000000;

      let poolPositionCount = await store
        .getState()
        .blockchain.poolPositionContract.methods.balanceOf(store.getState().blockchain.account)
        .call();
      let poolPositions = [];
      for (let i = 0; i < parseFloat(poolPositionCount); i++) {
        let poolPositionTokenId = await store
          .getState()
          .blockchain.poolPositionContract.methods.tokenOfOwnerByIndex(store.getState().blockchain.account, i)
          .call();
        let position = await store
          .getState()
          .blockchain.poolPositionContract.methods.positions(poolPositionTokenId)
          .call();
        if (position.token1 == deyeAddress) {
          position.id = poolPositionTokenId;
          poolPositions.push(position);
        }
      }

      let stakedIds = store.getState().blockchain.stakedTokenIds;
      let stakedPositions = [];
      for (let i = 0; i < stakedIds.length; i++) {
        let deposit = await store
          .getState()
          .blockchain.stakerContract.methods.deposits(stakedIds[i])
          .call();
        console.log(deposit);
        if (deposit.numberOfStakes == 0) {
          continue
        }
        let poolPositionTokenId = stakedIds[i];
        let reward = await store
          .getState()
          .blockchain.stakerContract.methods.getRewardInfo(
            ["0xE81b6ef5eF04972D9d0548848024058b59d00Ff0","0xb690cb96839fe494a7427f9bb1da922872f4e2d2",1651717000,1659925000,"0xC932C7E17fec62863fFd630a254Fa780EED88ac5"],
            poolPositionTokenId
          )
          .call();
        stakedPositions.push({id: poolPositionTokenId, reward: reward.reward});
      }

      let commanderCount = await store
        .getState()
        .blockchain.commanderContract.methods.balanceOf(store.getState().blockchain.account)
        .call();

      dispatch(
        fetchHubDataSuccess({
          deyeInWallet: parseFloat(deyeInWallet),
          deyeClaimed: parseFloat(deyeClaimed),
          marketDeyeAllowance: parseFloat(marketDeyeAllowance),
          activeItems: activeItems,
          notStartedItems: notStartedItems,
          soldItems: soldItems,
          poolDeye: parseFloat(poolDeye),
          poolEth: parseFloat(poolEth),
          poolPositions: poolPositions,
          stakedPositions: stakedPositions,
          commanderCount: commanderCount,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchHubDataFailed("Could not load data from contract."));
    }
  };
};
